<template>
  <div>
    <p class="is-size-7 has-text-weight-light">
      {{ getDate(event.date) }}
    </p>
    <p class="title is-size-5 has-text-dark">Registered owner change</p>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'TimelineOwner',
  props: {
    event: {
      type: Object,
      required: true
    },
    vrm: {
      type: String,
      required: true
    }
  },
  methods: {
    getDate(value) {
      return formatDate(value, 'do MMMM yyyy')
    }
  }
}
</script>
